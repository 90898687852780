import React from "react"

import Layout from "../components/particles/Layout"
import PageHead from "../components/molecules/PageHead"

const NotFoundPage = () => {
  const content = {
    title: "NOT FOUND",
    content: "You just hit a page that doesn&#39;t exist... the sadness.",
  }
  return (
    <Layout>
      <PageHead content={content} />
    </Layout>
  )
}

export default NotFoundPage
